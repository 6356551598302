// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';
import { result } from 'lodash';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    usersS1: [],
    usersS2: [],
    followers: [],
    friendRequests: [],
    friends: [],
    gallery: [],
    posts: [],
    detailCards: [],
    simpleCards: [],
    profileCards: []
};

const slice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // GET USERS STYLE 1
        getUsersListStyle1Success(state, action) {
            state.usersS1 = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function checkAccount(data, callback) {
    return async () => {
        try {
            const response = await axios.get('/api/user/check-account', {
                params: data
            });
            if(callback){
                callback(response.data);
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            callback({result: 'failed'});
        }
    };
}

export function sendCode(data, callback) {
    return async () => {
        try {
            const response = await axios.post('/api/user/code', data);
            if(callback){
                callback(response.data);
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            callback({result: 'failed'});
        }
    };
}

export function verify(data, callback) {
    return async () => {
        try {
            const response = await axios.post('/api/user/verify', data);
            if(callback){
                callback(response.data);
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            callback({result: 'failed'});
        }
    };
}

export function accept(data, callback) {
    return async () => {
        try {
            const response = await axios.post('/api/user/accept', data);
            if(callback){
                callback(response.data);
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            callback({result: 'failed'});
        }
    };
}

export function register(data, callback) {
    return async () => {
        try {
            const response = await axios.post('/api/user/register', data);
            if(callback){
                callback(response.data);
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            callback({result: 'failed'});
        }
    };
}

export function contactUs(data, callback) {
    return async () => {
        try {
            const response = await axios.post('/api/user/contact', data);
            if(callback){
                callback(response.data);
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            callback({result: 'failed'});
        }
    };
}