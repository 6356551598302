import { HashLink } from 'react-router-hash-link';
import { useNavigate, useLocation } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Button, Box } from '@mui/material';

// project imports
import LogoSection from '../LogoSection';
import SearchSection from './SearchSection';
import MobileSection from './MobileSection';
import ProfileSection from './ProfileSection';
import LocalizationSection from './LocalizationSection';
import MegaMenuSection from './MegaMenuSection';
import NotificationSection from './NotificationSection';
import { useDispatch, useSelector } from 'store';
import { openDrawer } from 'store/slices/menu';

// assets
import { IconMenu2 } from '@tabler/icons';
import projectLogo from 'assets/images/project-logo.png';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = () => {
    const theme = useTheme();

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { drawerOpen } = useSelector((state) => state.menu);

    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
            <Box
                component="img"
                alt="Caterpillar Logo"
                src={projectLogo}
                sx={{ width: '100%', maxWidth: '206px', height: 'auto' }}
                onClick={() => {
                    navigate(`/`);
                }}
            />
            <Box sx={{ display: 'flex', gap: '16px' }}>
                <Button
                    variant="text"
                    size="large"
                    sx={{
                        color: '#7A2897',
                        fontWeight: 700,
                        '&:hover': {
                            color: '#7A2897'
                        }
                    }}
                    href='https://portal2.caterpillarai.io'
                >
                    <FormattedMessage id="header-button-login" />
                </Button>
                {/* <HashLink smooth to="/#price-plan">
                    <Button
                        variant="contained"
                        size="large"
                        sx={{
                            borderRadius: '16px',
                            backgroundColor: '#7A2897',
                            color: '#FFFFFF',
                            fontWeight: 700,
                            '&:hover': {
                                backgroundColor: '#7A2897',
                                color: '#FFFFFF'
                            }
                        }}
                    >
                        <FormattedMessage id="header-button-try-for-free-now" />
                    </Button>
                </HashLink> */}
            </Box>
        </Box>
    );
};

export default Header;
