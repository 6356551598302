import React, { useMemo, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { Box, Grid, Link, MenuItem, Select, Typography } from '@mui/material';
import { Language, KeyboardArrowDown } from '@mui/icons-material';

import useConfig from 'hooks/useConfig';

import { format } from 'date-fns';

const Footer = () => {
    const { locale, onChangeLocale } = useConfig();

    const getLangName = (lang) => {
        let name = '';
        switch (lang) {
            case 'en':
                name = <FormattedMessage id="language-en" />;
                break;
            case 'zh':
                name = <FormattedMessage id="language-zh" />;
                break;
            default:
                name = null;
        }

        return name;
    };

    return (
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '40px 0' }}>
            <Box>
                <Select
                    id="language"
                    name="language"
                    displayEmpty
                    IconComponent={KeyboardArrowDown}
                    value={locale}
                    renderValue={() => (
                        <Typography
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                fontSize: '16px',
                                fontWeight: 400,
                                color: '#2D2D2D'
                            }}
                        >
                            <Language sx={{ marginRight: '16px' }} />
                            <span style={{ lineHeight: 1 }}>{getLangName(locale)}</span>
                        </Typography>
                    )}
                    onChange={(e) => {
                        onChangeLocale(e.target.value);
                    }}
                    sx={{
                        width: '100%',
                        minWidth: '150px',
                        '&.MuiOutlinedInput-root': {
                            fieldset: {
                                borderColor: '#C1C9D2'
                            },
                            div: {
                                bgcolor: '#FFFFFF'
                            },
                            '&:hover fieldset': {
                                borderColor: '#7A2897'
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: '#7A2897'
                            }
                        }
                    }}
                >
                    <MenuItem value="en"><FormattedMessage id="language-en" /></MenuItem>
                    <MenuItem value="zh"><FormattedMessage id="language-zh" /></MenuItem>
                </Select>
            </Box>
            <Typography sx={{ fontSize: '16px', fontWeight: 400, color: '#2D2D2D' }}>
                Copyright © Hotmob Limited {format(new Date(), 'yyyy')}. All Rights Reserved.
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box sx={{ display: 'flex', gap: '20px' }}>
                    <Link
                        href="/privacy-policy"
                        underline="always"
                        sx={{ color: '#2D2D2D', textDecorationColor: '#2D2D2D' }}
                    >
                        <FormattedMessage id="footer-privacy-policy" />
                    </Link>
                    <Link
                        href="/terms-and-conditions"
                        underline="always"
                        sx={{ color: '#2D2D2D', textDecorationColor: '#2D2D2D' }}
                    >
                        <FormattedMessage id="footer-terms-and-conditions" />
                    </Link>
                </Box>
            </Box>
        </Grid>
    );
};

export default Footer;
