// routing
import Routes from 'routes';

// project imports
import Locales from 'ui-component/Locales';
import NavigationScroll from 'layout/NavigationScroll';
import RTLLayout from 'ui-component/RTLLayout';
import Snackbar from 'ui-component/extended/Snackbar';
import ErrorSnackbar from 'ui-component/extended/ErrorSnackbar';
import ThemeCustomization from 'themes';

// auth provider
import { FirebaseProvider as AuthProvider } from 'contexts/FirebaseContext';
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
// import { AWSCognitoProvider as AuthProvider } from 'contexts/AWSCognitoContext';
// import { JWTProvider as AuthProvider } from 'contexts/JWTContext';
// import { Auth0Provider as AuthProvider } from 'contexts/Auth0Context';

// ==============================|| APP ||============================== //

const App = () => (
    <ThemeCustomization>
        {/* RTL layout */}
        <RTLLayout>
            <Locales>
                <GoogleReCaptchaProvider
                    reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                    scriptProps={{
                        async: false,
                        defer: true,
                        appendTo: "head",
                        nonce: undefined,
                    }}
                    >
                    <NavigationScroll>
                        <AuthProvider>
                            <>
                                <Routes />
                                <Snackbar />
                                <ErrorSnackbar />
                            </>
                        </AuthProvider>
                    </NavigationScroll>
                </GoogleReCaptchaProvider>
            </Locales>
        </RTLLayout>
    </ThemeCustomization>
);

export default App;
