import { lazy } from 'react';

// project imports
import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// sample page routing
const HomePage = Loadable(lazy(() => import('views/home-page')));
const AccaptInvitationPage = Loadable(lazy(() => import('views/accept-invitation-page')));
const PrivacyPolicyPage = Loadable(lazy(() => import('views/privacy-policy-page')));
const TermsAndConditionsPage = Loadable(lazy(() => import('views/terms-and-conditions-page')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <HomePage />
        },
        {
            path: '/accept',
            element: <AccaptInvitationPage />
        },
        {
            path: '/privacy-policy',
            element: <PrivacyPolicyPage />
        },
        {
            path: '/terms-and-conditions',
            element: <TermsAndConditionsPage />
        }
    ]
};

export default MainRoutes;
