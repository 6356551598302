import React, { useMemo, useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { createStyles, makeStyles, Theme } from '@mui/styles';
import { AppBar, Box, Container, CssBaseline, Grid, Toolbar, useMediaQuery } from '@mui/material';
import clsx from 'clsx';

// project imports
import Breadcrumbs from 'ui-component/extended/Breadcrumbs';
import Header from './Header';
import Footer from './Footer';
import Sidebar from './Sidebar';
import navigation from 'menu-items';
import useConfig from 'hooks/useConfig';
import { drawerWidth } from 'store/constant';
import { openDrawer } from 'store/slices/menu';
import { useDispatch, useSelector } from 'store';

// assets
import { IconChevronRight } from '@tabler/icons';

import headerbg from 'assets/images/header-bg.png';

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    ...theme.typography.mainContent,
    ...(!open && {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.shorter
        }),
        [theme.breakpoints.up('md')]: {
            marginLeft: -(drawerWidth - 20),
            width: `calc(100% - ${drawerWidth}px)`
        },
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px',
            width: `calc(100% - ${drawerWidth}px)`,
            padding: '16px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px',
            width: `calc(100% - ${drawerWidth}px)`,
            padding: '16px',
            marginRight: '10px'
        }
    }),
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.shorter
        }),
        marginLeft: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        width: `calc(100% - ${drawerWidth}px)`,
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px'
        }
    })
}));

const useStyles = makeStyles((theme) =>
    createStyles({
        headerOnTop: {
            top: 0,
            padding: 0
        },
        toolbarOnTop: {
            borderRadius: '0px !important',
            padding: '16px 64px !important'
        }
    })
);

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
    const theme = useTheme();
    const classes = useStyles();
    const location = useLocation();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'));

    const dispatch = useDispatch();
    const { drawerOpen } = useSelector((state) => state.menu);
    const { container } = useConfig();

    const [shouldHeaderMoveTop, setShouldHeaderMoveTop] = useState(false);

    useEffect(() => {
        window.addEventListener('scroll', (e) => {
            const scroll = window.pageYOffset;
            if (scroll > 20) {
                setShouldHeaderMoveTop(true);
            } else {
                setShouldHeaderMoveTop(false);
            }
        });
    }, []);

    React.useEffect(() => {
        dispatch(openDrawer(!matchDownMd));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [matchDownMd]);

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            {/* header */}
            <AppBar
                enableColorOnDark
                position="fixed"
                color="inherit"
                elevation={0}
                className={clsx(shouldHeaderMoveTop && classes.headerOnTop)}
                sx={{
                    bgcolor: 'transparent',
                    padding: '0 40px',
                    top: 40,
                    transition: 'all 0.3s ease-in'
                }}
            >
                <Toolbar
                    className={clsx(shouldHeaderMoveTop && classes.toolbarOnTop)}
                    sx={{
                        backgroundImage: `url(${headerbg})`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        borderRadius: '16px',
                        transition: 'all 0.3s ease-in'
                    }}
                >
                    <Header />
                </Toolbar>
            </AppBar>

            {/* main content */}
            <Main
                sx={{
                    background: 'transparent',
                    padding: 0,
                    margin: '0 0 0 0 !important',
                    width: '100% !important',
                    minHeight: '100vh',
                    borderRadius: 0
                }}
            >
                {/* breadcrumb */}
                <Outlet />
                <Box
                    sx={{
                        width: '100%',
                        bgcolor: location.pathname === '/privacy-policy' || location.pathname === '/terms-and-conditions' ? '#F1F1F1' : '#E2D7F4'
                    }}
                >
                    <Container>
                        <Grid container spacing={2}>
                            <Footer />
                        </Grid>
                    </Container>
                </Box>
            </Main>
        </Box>
    );
};

export default MainLayout;
