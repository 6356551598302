import * as React from 'react';

// material-ui
import { Alert, Button, Fade, Grow, IconButton, Slide } from '@mui/material';
import MuiSnackbar from '@mui/material/Snackbar';

// assets
import CloseIcon from '@mui/icons-material/Close';

import { useDispatch, useSelector } from 'store';
import { closeSnackbar } from 'store/slices/snackbar';
import { closeBackdrop } from 'store/slices/backdrop';

// animation function
function TransitionSlideLeft(props) {
    return <Slide {...props} direction="left" />;
}

function TransitionSlideUp(props) {
    return <Slide {...props} direction="up" />;
}

function TransitionSlideRight(props) {
    return <Slide {...props} direction="right" />;
}

function TransitionSlideDown(props) {
    return <Slide {...props} direction="down" />;
}

function GrowTransition(props) {
    return <Grow {...props} />;
}

// animation options
const animation = {
    SlideLeft: TransitionSlideLeft,
    SlideUp: TransitionSlideUp,
    SlideRight: TransitionSlideRight,
    SlideDown: TransitionSlideDown,
    Grow: GrowTransition,
    Fade
};

// ==============================|| ERROR SNACKBAR ||============================== //

const ErrorSnackbar = () => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);

    const [open, setOpen] = React.useState(false);
    const [message, setMessage] = React.useState('');

    React.useEffect(() => {
        let msg = 
            user.error?.message;
        if(msg){
            setMessage(msg);
            setOpen(true);
            dispatch(closeBackdrop());
        }
        
    }, [user.error]);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setMessage(null);
        setOpen(false);
    };

    return (
        <>
            <MuiSnackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
                message={message}
                TransitionComponent={animation['Fade']}
                action={
                    <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose} sx={{ mt: 0.25 }}>
                        <CloseIcon fontSize="small" />
                    </IconButton>
                }
            />
        </>
    );
};

export default ErrorSnackbar;
